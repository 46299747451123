<template>
  <div class="content-rastrear-pedido">
    <div class="content-max-width">
      <h2 class="text-center">Rastrear pedido</h2>
      <form-follow-order v-if="step == 1" @next="step += 1" />
      <follow-up-order v-if="step == 2" :data="dataOrder" @back="step = 1" />
    </div>
  </div>
</template>
<script>
import FollowUpOrder from '@/components/followOrder/FollowUpOrder'
import FormFollowOrder from '@/components/followOrder/FormFollowOrder.vue'
import { mapState } from 'vuex'
export default {
  components: { FollowUpOrder, FormFollowOrder },
  data() {
    return {
      step: 1,
    }
  },
  computed: {
    ...mapState('user', ['dataOrder']),
  },
}
</script>
