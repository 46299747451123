<template>
  <div class="container-form-follow-order">
    <div class="w-100 my-5">
      <p class="mb-5 col-12 col-md-10 mx-auto text-center">
        Debes proporcionarnos tu dirección de correo electrónico y tu número de
        pedido para realizar el seguimiento.
      </p>
      <div class="col-12 col-lg-5 col-xl-4 mb-4 mx-auto px-md-3">
        <div class="input-simple-clover">
          <label
            :class="validateEmail ? 'invalid' : ''"
            class="label-input"
            :style="email ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Correo electrónico</label
          >
          <input
            v-model="email"
            :class="validateEmail ? 'invalid-error' : ''"
            placeholder="Ejemplo@clover.com"
            type="email"
            @blur="validateBlur('validateEmail')"
            @focus="validateEmail = false"
          />
          <span v-if="validateEmail" class="inavalida-input">{{
            email ? 'correo invalido' : 'Campo requerido'
          }}</span>
        </div>
      </div>
      <div class="col-12 col-lg-5 col-xl-4 mb-4 mx-auto px-md-3">
        <div class="input-simple-clover">
          <label
            :class="validateNPedido ? 'invalid' : ''"
            class="label-input"
            :style="nPedido ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Número de pedido</label
          >
          <input
            v-model="nPedido"
            :class="validateNPedido ? 'invalid-error' : ''"
            placeholder="Número de pedido"
            type="number"
            @blur="validateBlur('validateNPedido')"
            @focus="validateNPedido = false"
          />
          <span v-if="validateNPedido" class="inavalida-input">{{
            nPedido ? 'Número de pedido invalido' : 'Campo requerido'
          }}</span>
        </div>
      </div>
      <div class="col-12 col-lg-5 col-xl-4 mb-4 mx-auto px-md-3">
        <button class="bnt-primary w-100" @click="validatePedido()">
          Continuar
        </button>
      </div>
      <div class="col-12 col-lg-5 col-xl-4 mb-4 mx-auto px-md-3">
        <span class="text-error">{{ msgGetInfoOrder }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      email: '',
      validateEmail: false,
      nPedido: '',
      validateNPedido: false,
      cogValidate: '',
    }
  },
  computed: {
    ...mapState('user', ['errorGetInfoOrder', 'msgGetInfoOrder']),
  },
  methods: {
    ...mapActions('user', ['getInfoOrder']),
    /* eslint-disable */
    validateBlur(blur) {
      if (blur == 'validateEmail') {
        const re =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        this.validateEmail = !re.test(this.email)
      } else if (blur == 'validateNPedido') {
        this.validateNPedido = !(this.nPedido.length > 3)
      }
    },
    async validatePedido() {
      if (!this.validateEmail && !this.validateNPedido) {
        await this.getInfoOrder({
          email: this.email,
          pedidoId: this.nPedido,
        }).then(() => {
          if (!this.errorGetInfoOrder) {
            this.$emit('next', 1)
          }
        })
      }
    },
  },
}
</script>
