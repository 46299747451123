import { render, staticRenderFns } from "./FormFollowOrder.vue?vue&type=template&id=3490f973"
import script from "./FormFollowOrder.vue?vue&type=script&lang=js"
export * from "./FormFollowOrder.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports